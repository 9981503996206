import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const addToCartApi = async (product) => {
  const access_token = sessionStorage.getItem("access_token");
  const urlencoded = new URLSearchParams();
  if (product.type === "s") {
    urlencoded.append("type", product.type);
    urlencoded.append("quantity", Number(1));
    urlencoded.append("currency", product.pricein);
    urlencoded.append("simple_pro_id", product.productid);
  } else {
    urlencoded.append("type", product.type);
    urlencoded.append("quantity", Number(1));
    urlencoded.append("currency", product.pricein);
    urlencoded.append("variantid", product.variantid);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/addtocart`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      }
    );

    if (response.status === 200) {
      const data = await response.json();

      switch (data.msg.trim()) {
        case "Product not found !":
          toast.error(` ${data.msg} in func `, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          break;
        case "Product quantity updated !":
          toast.info(` ${data.msg} in func `, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          break;
        case "Item added to cart successfully !":
          toast.success(` ${data.msg} in func `, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          return data.data;

          break;

        default:
          break;
      }
    } else {
      throw new Error("Something went wrong!");
    }
  } catch (error) {
    alert(error);
  }
};

const inDecToCartApi = async (bodyUrlEncoded) => {
  const TOKEN = sessionStorage.getItem("access_token");
  const myHeaders = { Authorization: "Bearer " + TOKEN };

  let urlencoded = new URLSearchParams();
  urlencoded.append("cartid", bodyUrlEncoded?.cartId);
  urlencoded.append("quantity", bodyUrlEncoded?.ProductQty);
  urlencoded.append("currency", bodyUrlEncoded?.currency);
  if (
    bodyUrlEncoded?.simple_product_varient_id !== undefined &&
    bodyUrlEncoded?.simple_product_varient_id !== null
  ) {
    urlencoded.append(
      "simple_product_varient_id",
      bodyUrlEncoded?.simple_product_varient_id
    );
  }

  try {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/increase-quantity/in/cart`, {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    }).then(async (response) => {
      const data = await response.json();
    });
  } catch (error) {
    alert(error);
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    // cartTotalQuantity: 0,
    // cartTotalAmount: 0,
    // items: [],
    searchterm: "",
    pro_detail_id: localStorage.getItem("pro_id")
      ? localStorage.getItem("pro_id")
      : "",
    pro_detail_type: localStorage.getItem("pro_type")
      ? localStorage.getItem("pro_type")
      : "",
    catId: localStorage.getItem("cat_id") ? localStorage.getItem("cat_id") : "",
    brandId: localStorage.getItem("brand_id")
      ? localStorage.getItem("brand_id")
      : "",
    featured: "",
    addResponse: [],
    cartQuantity: "" ? "" : 0,
    brandListView: "",
    brandName: "",
    orderidName: 0,
    sellerListView: JSON.parse(localStorage.getItem("sellerlist"))
      ? JSON.parse(localStorage.getItem("sellerlist"))
      : [],
    userId: sessionStorage.getItem("userId")
      ? sessionStorage.getItem("userId")
      : null,
    removeProFromUi: "",
    addressId: "",
    headerImage: [],
    cityId: "3659",
    stateId: "35",
    cartId: "",
  },

  reducers: {
    addToCart(state, action) {
      const product = action.payload;
      addToCartApi(product);
    },
    cartRecall(state, action) {
      const QTY = action.payload;
      state.cartQuantity = QTY;
    },
    proRemoveFromUI(state, action) {
      const removeId = action.payload;
      state.removeProFromUi = removeId;
    },

    increaseDecreaseProductQuantity(state, action) {
      const newItem = action.payload;
      inDecToCartApi(newItem);
    },
    searchterm(state, action) {
      const newSearchterm = action.payload;
      state.searchterm = newSearchterm;
    },
    productDetailQuery(state, action) {
      const newProductDetail = action.payload;
      {
        if (newProductDetail.id) {
          state.pro_detail_id = newProductDetail.id;
          localStorage.setItem("pro_id", state.pro_detail_id);
          state.pro_detail_type = newProductDetail.type;
          localStorage.setItem("pro_type", state.pro_detail_type);
        } else {
          if (newProductDetail.productid) {
            state.pro_detail_id = newProductDetail.productid;
            localStorage.setItem("pro_id", state.pro_detail_id);
            state.pro_detail_type = newProductDetail.type;
            localStorage.setItem("pro_type", state.pro_detail_type);
          } else {
            state.pro_detail_id = newProductDetail.product_id;
            localStorage.setItem("pro_id", state.pro_detail_id);
            state.pro_detail_type = newProductDetail.allProductDetail.type;
            localStorage.setItem("pro_type", state.pro_detail_type);
          }
        }
      }
    },

    catIdHandler(state, action) {
      const newSearchterm = action.payload;
      state.catId = newSearchterm;
      localStorage.setItem("cat_id", state.catId);
    },
    brandIdHandler(state, action) {
      const newSearchterm = action.payload;
      state.brandId = newSearchterm;
      localStorage.setItem("brand_id", state.brandId);
    },
    featureProduct(state, action) {
      const newSearchterm = action.payload;
      state.featured = newSearchterm;
    },
    sellerList(state, action) {
      const sellerId = action.payload;
      state.sellerListView = sellerId;
    },
    brandlist(state, action) {
      const BrandName = action.payload;
      state.brandListView = BrandName;
    },
    brandNameList(state, action) {
      const BrandNameview = action.payload;
      state.brandName = BrandNameview;
    },
    orderIdList(state, action) {
      const orderId = action.payload;
      state.orderidName = orderId;
    },
    addressIdHandle(state, action) {
      const address = action.payload;
      state.addressId = address;
    },
    headerImage(state, action) {
      const headerImageAction = action.payload;
      state.headerImage = headerImageAction;
    },
    cityIdPassingToHome(state, action) {
      const cityIdPassingToHomeAction = action.payload;
      state.cityId = cityIdPassingToHomeAction;
    },
    stateIdPassingToHome(state, action) {
      const cityIdPassingToHomeAction = action.payload;
      state.cityId = cityIdPassingToHomeAction;
    },
    cartIdPassingToPro(state, action) {
      const cartIdPassingToProAction = action.payload;
      state.cartId = cartIdPassingToProAction;
    },
  },
});
export const {
  addToCart,
  cartRecall,
  increaseDecreaseProductQuantity,
  searchterm,
  productDetailQuery,
  cartIdPassingToPro,
  catIdHandler,
  featureProduct,
  sellerList,
  brandlist,
  headerImage,
  brandIdHandler,
  brandNameList,
  orderIdList,
  proRemoveFromUI,
  removeCartHandler,
  addressIdHandle,
  cityIdPassingToHome,
  stateIdPassingToHome,
} = cartSlice.actions;

export default cartSlice.reducer;
