import { createSlice } from "@reduxjs/toolkit";
const wishSlice = createSlice({
  name: "wishlist",
  initialState: {
    AddToWishlist: "",
    // is_in_wishlist:false
  },
  reducers: {
    wishQuantity(state, action) {
      //   const product = action.payload;
      const QTY = action.payload;
      state.AddToWishlist = QTY;

      // state.is_in_wishlist=action.payload

      //   addToCartApi(product);
    },
  },
});

export const { wishQuantity } = wishSlice.actions;
export default wishSlice.reducer;
