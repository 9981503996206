import React, { lazy } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";

const Login = lazy(() => import("./pages/Login/Login"));
const AllProducts = lazy(() => import("./pages/Shop/Components/AllProducts"));
const FlashdealProducts = lazy(() =>
  import("./pages/FlashdealProducts/FlashdealProducts")
);
const SellerProduct = lazy(() =>
  import("./pages/SellerPage/SellerProduct/SellerProduct")
);
const Home = lazy(() => import("./pages/Home/Home"));
const Register = lazy(() => import("./pages/Register/Register"));
const About = lazy(() => import("./pages/About/About"));
const Checkout = lazy(() => import("./pages/CheckOut/Checkout"));
const CategoryPage = lazy(() => import("./pages/CategoryPages/CategoryPage"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const Faq = lazy(() => import("./pages/Faq/Faq"));
const Offer = lazy(() => import("./pages/Offer/Offer"));
const WishList = lazy(() => import("./pages/WishList/WishList"));
const BrandList = lazy(() => import("./pages/BrandList/BrandList"));
const Error404 = lazy(() => import("./pages/Error404/Error404"));
const BrandProduct = lazy(() => import("./pages/Brand/BrandProduct"));
const OrderTracker = lazy(() => import("./pages/OrderTracker/OrderTracker"));
const OrderInvoice = lazy(() => import("./pages/OrderInvoice/OrderInvoice"));
const OrderHistory = lazy(() => import("./pages/OrderHistory/OrderHistory"));
const EmailTemplate = lazy(() => import("./pages/EmailTemplate/EmailTemplate"));
const ForgotPassword = lazy(() =>
  import("./pages/ResetPassword/ForgotPassword")
);

const TermsAndCondition = lazy(() =>
  import("./pages/TermsAndCondition/TermsAndCondition")
);
const Wallet = lazy(() => import("./pages/Wallet/Wallet"));
const SellerLogin = lazy(() =>
  import("./pages/SellerPage/SellerLogin/SellerLogin")
);
const SellerRegister = lazy(() =>
  import("./pages/SellerPage/SellerRegister/SellerRegister")
);
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const Seller = lazy(() => import("./pages/SellerPage/Seller/Seller"));
const SellerList = lazy(() =>
  import("./pages/SellerPage/SellerList/SellerList")
);
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Privacy = lazy(() => import("./pages/Privacy/Privacy"));
const ProductDetails = lazy(() => import("./pages/Product/ProductDetails"));
const CategoryProduct = lazy(() =>
  import("./pages/CategoryProduct/CategoryProduct")
);
const SearchedProducts = lazy(() => import("./pages/Shop/SearchedProducts"));
const ChangePassword = lazy(() =>
  import("./pages/ChangePassword/ChangePassword")
);

const LazyRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/checkout" component={Checkout} />
      <Route exact path="/category" component={CategoryPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/offer" component={Offer} />
      <Route exact path="/wish-list" component={WishList} />
      <Route exact path="/brand-list" component={BrandList} />
      <Route exact path="/brand/:id" component={BrandProduct} />
      <Route exact path="/order-tracker" component={OrderTracker} />
      <Route exact path="/order-invoice" component={OrderInvoice} />
      <Route exact path="/order-history" component={OrderHistory} />
      <Route exact path="/email-template" component={EmailTemplate} />
      <Route exact path="/wallet" component={Wallet} />
      <Route exact path="/seller-login" component={SellerLogin} />
      <Route exact path="/seller-register" component={SellerRegister} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/seller" component={Seller} />
      <Route exact path="/seller-list" component={SellerList} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/product/:id" component={ProductDetails} />
      <Route exact path="/category/:id" component={CategoryProduct} />
      <Route exact path="/childcategory/:id" component={CategoryProduct} />
      <Route
        exact
        path="/products/search=:searchkey"
        component={SearchedProducts}
      />
      <Route exact path="/products" component={AllProducts} />
      <Route exact path="/seller-product" component={SellerProduct} />
      <Route exact path="/seller-product/:id" component={SellerProduct} />
      <Route exact path="/terms-condition" component={TermsAndCondition} />
      <Route exact path="/change-password" component={ChangePassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/flashdeals-products/:id"
        component={FlashdealProducts}
      />

      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default LazyRouter;
