import { Suspense, useEffect } from "react";
import Loader from "./assets/Loader/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./utils/ScrollToTop";
import LazyRouter from "./LazyRouter";
import $ from "jquery";

function App() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    $(window).on("scroll", function () {
      $(this).scrollTop() > 700 ? $(".backtop").show() : $(".backtop").hide();
    });
  });

  return (
    <Suspense fallback={<Loader />}>
      <a className="backtop fas fa-arrow-up" onClick={scrollToTop} />
      <ToastContainer />
      <ScrollToTop>
        <LazyRouter />
      </ScrollToTop>
    </Suspense>
  );
}

export default App;
