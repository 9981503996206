
import {  configureStore } from "@reduxjs/toolkit";
import cartSlice from "../ReduxToolkit/cartSlice";
import wishSlice from "../ReduxToolkit/wishSlice";

const Store = configureStore({
  reducer: {
    cart: cartSlice,
    wishlist:wishSlice
  },
});

export default Store;
